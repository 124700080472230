import Bubble from "./bubble"
import { ease } from "../utils"

export default class Capsule {
  constructor(p5) {
    this.p5 = p5

    this.dt = 0 // for animation
    this.transitionElapsed = 0
    this.angle = 0 // for sin() update

    this.hover = false
    this.hoverX = 0
    this.hoverY = 0
    this.hoverW = 0
    this.hoverH = 0

    this.hoverTint = this.p5.color(255)
    this.cableTint = this.p5.color(0)
    this.waterAlpha = 1.0
    this.capsuleTopAlpha = 1.0

    this.octoReady = false
    // sleep, transition1(to glow), transition2(to no top), awake
    this.state = "sleep"

    this.bubbles = []
    for (let i = 0; i < 12; i++) {
      const b = new Bubble(p5)
      this.bubbles.push(b)
    }
  }

  setId(id) {
    this.id = id
  }

  setImages(imgs) {
    // pass object
    this.imgs = imgs
  }

  setOcto(octo) {
    this.octo = octo
  }

  setPosition(x, y) {
    this.x = x
    this.y = y
  }

  setHoverPosition(x, y) {
    this.hoverX = x
    this.hoverY = y
  }

  setHoverSize(w, h) {
    this.hoverW = w
    this.hoverH = h
  }

  updateDeltaTime(dt) {
    this.dt = dt
  }

  setGlobalTint(tint) {
    this.globalTint = tint
  }

  setWaterWhite(alpha) {
    this.waterWhiteAlpha = alpha
  }

  setWaterGlow(alpha) {
    this.waterGlow = 5
    this.waterGlowAlpha = alpha
  }

  setState(state) {
    // sleep, transition1, transition2, awake
    this.state = state
  }

  setCableTint(t) {
    this.cableTint = t
  }

  fillWater(bool) {
    this.waterFilled = bool
  }

  removeTop(bool) {
    this.capsuleTopOff = bool
  }

  update() {
    this.angle += this.dt

    for (const b of this.bubbles) {
      b.update(this.dt)
    }

    if (this.state === "transition1") {
      this.transitionElapsed += this.dt
      this.transitionDuration = 1 // sec
      const t = this.transitionElapsed / this.transitionDuration
      const lt = ease(t, "easeInCubic")
      const gt = this.p5.lerp(10, 150, lt) // 10 dark 200 normal 150 full glow
      const ww = this.p5.lerp(0, 200, lt) // water white 0 normal, 200 strong
      const wg = this.p5.lerp(120, 250, lt) // water glow 120 normal, 250 strong
      const ct = this.p5.lerp(0, 255, lt)
      this.setGlobalTint(gt)
      this.setWaterWhite(ww)
      this.setWaterGlow(wg)
      this.setCableTint(this.p5.color(ct))

      if (this.transitionElapsed >= this.transitionDuration) {
        this.transitionElapsed = 0
        this.transitionDuration = 1
        this.state = "transition2"
      }
    } else if (this.state === "transition2") {
      this.transitionElapsed += this.dt
      const t = this.transitionElapsed / this.transitionDuration
      const lt = ease(t, "easeOutCubic")
      const gt = this.p5.lerp(150, 250, lt)
      this.waterAlpha = this.p5.lerp(1, 0, lt)
      this.capsuleTopAlpha = this.p5.lerp(1, 0, lt)
      this.octoReady = true
      this.setGlobalTint(gt)

      if (this.transitionElapsed >= this.transitionDuration) {
        this.state = "awake"
        this.waterFilled = false
        this.capsuleTopOff = true
      }
    } else if (this.state === "awake") {
      //
    }
  }

  reset() {
    this.hoverTint = this.p5.color(255)
  }

  display(pg) {
    // console.log(dt);

    const {
      capsuleBottomImg,
      capsuleTopImg,
      waterImg,
      waterWhiteImg,
      reflectionImg,
      cableImg,
      sleepOctoImg,
    } = this.imgs

    pg.push()
    pg.translate(this.x, this.y)

    // if (!this.hover && this.state === "sleep") pg.tint(10);
    // else pg.tint(this.globalTint);

    pg.tint(this.globalTint)

    pg.image(capsuleBottomImg, 0, 0)

    if (this.hover) {
      pg.push()
      pg.blendMode(this.p5.SCREEN)
      pg.noStroke()
      pg.fill(250 * Math.abs(Math.sin(this.angle * 1)), 200, 0)
      pg.rect(59.5, 78.5, 3, 3)
      pg.pop()
    }

    pg.push()
    if (this.state !== "awake") {
      pg.translate(
        Math.ceil(this.p5.sin(this.y * this.id + this.angle * 0.5) * 1),
        Math.ceil(this.p5.sin(this.y * this.id + this.angle * 1) * 1.4)
      )
    } else {
      pg.translate(0, 2)
    }
    if (this.hover) pg.tint(this.hoverTint)
    if (this.octo && this.octoReady) {
      this.octo.display(pg)
    } else {
      pg.image(sleepOctoImg, 0, 0)
    }
    pg.pop()

    if (this.waterFilled === true) {
      pg.push()
      pg.drawingContext.globalAlpha = this.waterAlpha

      // regular water
      pg.blendMode(this.p5.BLEND)
      pg.tint(255, 120)
      pg.image(waterImg, 0, 0)

      // water bubbles
      for (const b of this.bubbles) {
        b.display(pg)
      }

      pg.blendMode(this.p5.SCREEN)
      pg.tint(255, this.waterWhiteAlpha)
      pg.image(waterWhiteImg, 0, 0)

      // water glow
      pg.blendMode(this.p5.SCREEN)
      pg.tint(255, this.waterGlowAlpha)
      //   Math.abs(Math.sin(this.id + this.angle * 1)) * 8 + 8
      pg.drawingContext.filter = `blur(${this.waterGlow}px)`
      pg.image(waterImg, 0, 0)
      pg.drawingContext.filter = `none`
      pg.pop()
    }

    if (this.capsuleTopOff === false) {
      pg.push()
      pg.drawingContext.globalAlpha = this.capsuleTopAlpha
      pg.tint(255)
      pg.image(capsuleTopImg, 0, 0)
      pg.image(reflectionImg, 0, 0)
      pg.pop()
    }

    pg.blendMode(this.p5.BLEND)
    pg.tint(this.cableTint)
    pg.image(cableImg, 0, 0)
    pg.tint(255)
    pg.pop()

    pg.blendMode(this.p5.BLEND)
  }
}
