import Octo from "./octo"
import {
  fetchFxhashData,
  loadOctoImages,
  // rectify,
  scaleUpAll,
  shuffle,
} from "../utils"
// import collectionDataDummy from "../../data/collectionDataDummy";
import Capsule from "./capsule"

/**
 * In this sketch, I separated hover check as a function instead of having inside each object.
 * This requires setting hoverable position all manually. Not sure yet if this is better.
 * Maybe next time, check pixel (alpha === 0) to determine hoverable area.
 * Also, cut out empty area in each image part.
 *
 * REVIEW:
 * - do i keep track of different states/stages within class?
 * - or, create particular animation, etc in main sketch?
 */

const projectTitle = `HashOctoz-Play-Wakeup`

// static assets folder
const globalImagePath = `/assets`
const localImagePath = `/assets/images-wakeup` // TODO: need to use local image directory such as ./images/hotsprings
let imageData = {}

let collectionData = []

const origWidth = 120
const origHeight = 120

let pg // main offscreen graphicsd

let scaleFactor = 1
let factor = 1 // used for resizing canvas

let numOctoz = 3
let octoz = []
let octoInitialized = false
let activeOcto = null

let dataIsLoaded = false
let saveImage = false
let displayDialogue = false

let positions = [
  [61, 55], // 61, 54
  [61, 55],
  [61, 55],
]

let elapsedTime = 0

//******************** sketch specific variables */
let floorImg
let capsuleBottomImg
let capsuleTopImg
let waterImg
let waterWhiteImg
let reflectionImg
let cableImg
let sleepOctoImg

const numCapsules = 10
let capsules = []
// let activeCapsule = null;

let capsulePositions = [
  [-32, -80],
  [0, -64],
  [32, -48],
  [64, -32],
  [-64, -32],
  [-32, -16],
  [0, 0],
  [32, 16],
  [-64, 32],
  [-32, 48],
]

let capsuleHoverPositions = [
  [20, 27], // 5
  [52, 42], // 6
  [84, 59], // 7
]
let capsuleHoverSizes = [
  [17, 26], // 5
  [17, 26], // 6
  [17, 26], // 7
]

export default function BirthSketch(p) {
  let state = {}

  p.updateWithProps = props => {
    // state = Object.assign(state, props)
  }

  p.preload = preload(p)
  p.setup = setup(p, state)
  p.draw = draw(p)

  p.mouseReleased = mouseReleased(p)
  // p.touchStarted = touchStarted()
  // p.touchMoved = touchMoved()
  // p.touchEnded = touchEnded(p)

  p.keyTyped = keyTyped(p)
  p.windowResized = windowResized(p, state)
}

function preload(p) {
  return () => {
    // collectionData = collectionDataDummy;
    // dataIsLoaded = true
    fetchFxhashData(5164).then(r => {
      collectionData = r.data.generativeToken.entireCollection
      collectionData = shuffle(collectionData)
      dataIsLoaded = true
      console.log("collectionData:", collectionData)
    })

    collectionData = shuffle(collectionData)

    console.log("collectionData:", collectionData)

    // load octo images
    imageData = loadOctoImages(p, globalImagePath)
    // load "play" specific images
    floorImg = p.loadImage(`${localImagePath}/floor.png`)
    capsuleBottomImg = p.loadImage(`${localImagePath}/capsule-bottom.png`)
    capsuleTopImg = p.loadImage(`${localImagePath}/capsule-top.png`)
    waterImg = p.loadImage(`${localImagePath}/water-2.png`)
    waterWhiteImg = p.loadImage(`${localImagePath}/water-white.png`)
    reflectionImg = p.loadImage(`${localImagePath}/reflection.png`)
    cableImg = p.loadImage(`${localImagePath}/cable.png`)
    sleepOctoImg = p.loadImage(`${localImagePath}/octo.png`)
  }
}

function setup(p, props) {
  return () => {
    // const dim = calculateCanvasSize(p, factor, origWidth, origHeight)
    const dim = calculateCanvasSize(
      p,
      origWidth,
      origHeight,
      p.windowWidth,
      p.windowHeight
    )
    p.createCanvas(dim.w, dim.h)

    p.frameRate(30)
    // p.pixelDensity(1) // REVIEW: seems this doesn't matter
    p.noSmooth()
    p.textFont("PressStart2P")

    pg = p.createGraphics(origWidth * scaleFactor, origHeight * scaleFactor)
    pg.pixelDensity(1)
    // pg.colorMode(HSB, 360, 100, 100, 100);
    pg.textFont("PressStart2P")
    pg.textSize(8)
  }
}

function getObjectsReady(p) {
  if (!dataIsLoaded) return
  if (dataIsLoaded && !octoInitialized) {
    let numCounter = 0
    for (let i = 0; i < collectionData.length; i++) {
      if (
        collectionData[i].metadata &&
        collectionData[i].metadata.attributes &&
        numCounter < numOctoz
      ) {
        const octo = new Octo(p, collectionData[i])
        octo.setImageData(imageData)
        octo.flipDirection()
        octo.setAttributes(collectionData[i].metadata.attributes)
        {
          const x = positions[numCounter][0]
          const y = positions[numCounter][1]
          octo.setPosition(x, y)
        }
        octo.direction = p.random(1) < 0.5 ? -1 : 1
        octo.createOctoGraphics()
        octo.createOctoImage()
        octoz.push(octo)

        numCounter++
      }
    }

    for (let i = 0; i < numCapsules; i++) {
      const c = new Capsule(p)
      c.setId(i)
      c.setImages({
        capsuleBottomImg,
        capsuleTopImg,
        waterImg,
        waterWhiteImg,
        reflectionImg,
        cableImg,
        sleepOctoImg,
      })
      {
        const x = capsulePositions[i][0]
        const y = capsulePositions[i][1]
        c.setPosition(x, y)
      }
      if (i >= 5 && i <= 7) {
        c.setOcto(octoz[i - 5])
        c.octo.setupDialogue()
        c.setHoverPosition(
          capsuleHoverPositions[i - 5][0],
          capsuleHoverPositions[i - 5][1]
        )
        c.setHoverSize(capsuleHoverSizes[i - 5][0], capsuleHoverSizes[i - 5][1])
      } else {
        c.setOcto(null)
      }
      c.setCableTint(p.color(0))
      c.fillWater(true)
      c.removeTop(false)
      c.setGlobalTint(10) // 10 dark 200 normal 150 full glow
      c.setWaterWhite(0) // 0 normal, 200 strong
      c.setWaterGlow(120) // 120 normal, 250 strong
      capsules.push(c)
    }
    // bgImgScaled = rectify(p, bgImg, scaleFactor);
    octoInitialized = true
  }
}

function draw(p) {
  return () => {
    p.background(0, 0, 0)
    p.fill(255)
    p.textSize(p.width * 0.05)
    p.textAlign(p.CENTER)
    p.text("loading", p.width / 2, p.height / 2)
    p.cursor(p.ARROW)

    getObjectsReady(p)
    if (!octoInitialized) return

    const dt = p.deltaTime * 0.001
    elapsedTime += dt

    pg.background(0, 0, 0)

    // check hover capsules (3 main ones)
    for (let i = 7; i >= 5; i--) {
      const hovered = checkHover(p, capsules[i], p.mouseX, p.mouseY, factor)
      if (hovered) {
        p.cursor(p.HAND)
        capsules[i].hover = true
        capsules[i].octo.hover = true
        // capsules[i].hoverTint = p.color(100, 220, 220)
        break
      } else {
        capsules[i].hover = false
        capsules[i].octo.hover = false
        capsules[i].reset()
      }
    }

    // floor
    pg.push()
    pg.tint(150)
    pg.image(floorImg, 0, 0)
    pg.pop()

    // pg.fill(0, 100);
    // pg.rect(0, 0, origWidth, origHeight);

    for (let i = 0; i < capsules.length; i++) {
      const c = capsules[i]
      c.updateDeltaTime(dt)
      c.update()
      c.display(pg)
    }

    // reset hover capsules (3 main ones)
    for (let i = 7; i >= 5; i--) {
      capsules[i].reset()
    }

    // const pgScaled = rectify(p, pg, scaleFactor);
    // image(pgScaled, 0, 0, width, height);
    p.push()
    p.image(pg, 0, 0, p.width, p.height)
    p.pop()

    if (displayDialogue) {
      activeOcto.displayDialogue(pg)
    }

    if (saveImage) {
      const img = scaleUpAll(p, [pg], 8, origWidth, origHeight)
      img.save(projectTitle)
      saveImage = false
    }
  }
}

// all interactable objects must have same fields (obj.hoverX/Y/W/H)
function checkHover(p, obj, mx, my, factor) {
  // console.log(Math.floor(mx / factor), Math.floor(my / factor));
  if (
    mx / factor >= obj.hoverX &&
    mx / factor < obj.hoverX + obj.hoverW &&
    my / factor >= obj.hoverY &&
    my / factor < obj.hoverY + obj.hoverH
  ) {
    return true
  }
  return false
}

function calculateCanvasSize(
  p,
  origWidth,
  origHeight,
  windowWidth,
  windowHeight
) {
  let w, h
  if (windowWidth > windowHeight) {
    factor = Math.floor((windowHeight / origHeight) * 1)
    h = origHeight * factor
    w = h * (origWidth / origHeight)
    // scaleFactor = hFactor;
  } else {
    factor = Math.floor((windowWidth / origWidth) * 1)
    w = origWidth * factor
    h = w * (origHeight / origWidth)
    // scalefactor = wFactor;
  }

  w = Math.max(w, origWidth)
  h = Math.max(h, origHeight)

  return { w, h }
}

// mousePressed has a double clicking "bug" on mobile, so use released instead.
function mouseReleased(p) {
  return () => {
    for (let i = 7; i >= 5; i--) {
      const c = capsules[i]
      const hovered = checkHover(p, c, p.mouseX, p.mouseY, factor)
      if (hovered) {
        if (c.state === "sleep") {
          c.state = "transition1"
        } else if (c.state === "awake") {
          // console.log(i, c.state)
          displayDialogue = true
          activeOcto = c.octo
          activeOcto.advanceDialogueIndex()
          activeOcto.displayDialogue(pg)
        }
        break
      }
    }
    if (activeOcto && activeOcto.hover === false) {
      displayDialogue = false
      activeOcto = null
    }
  }
}

function touchStarted() {
  return false
}
function touchMoved() {
  return false
}
function touchEnded(p) {
  mouseReleased(p)
  // prevent default
  return false
}

function keyTyped(p) {
  return () => {
    if (p.key === "s") {
      saveImage = true
    }
  }
}

function windowResized(p, props) {
  return () => {
    // const dim = calculateCanvasSize(p, factor, origWidth, origHeight)
    const dim = calculateCanvasSize(
      p,
      origWidth,
      origHeight,
      p.windowWidth,
      p.windowHeight
    )
    p.resizeCanvas(dim.w, dim.h)
  }
}
