import BaseOcto from "../../classes/base-octo-v2"
import Dialogue from "./dialogue"
import { shuffle } from "../utils"

export default class Octo extends BaseOcto {
  constructor(p5, tokenData) {
    super(p5, tokenData)
    this.dialogueIndex = -1
  }

  flipDirection() {
    this.direction *= -1
    setTimeout(this.flipDirection.bind(this), this.p5.random(4000, 8000))
  }

  setupDialogue() {
    const d = new Dialogue(this.tokenData)
    this.dialogue = d.getDialogueBasic().concat(d.getDialogueSpecial())
    this.dialogue = shuffle(this.dialogue)
  }

  randomizeDialogueIndex() {
    this.dialogueIndex = Math.floor(this.p5.random(this.dialogue.length))
  }

  advanceDialogueIndex() {
    this.dialogueIndex++
    if (this.dialogueIndex >= this.dialogue.length) this.dialogueIndex = 0
  }

  createOctoGraphics() {
    const pg = this.p5.createGraphics(this.origWidth, this.origHeight)
    pg.pixelDensity(1)
    pg.image(this.bodyFill, 0, 0)
    pg.image(this.body, 0, 0)
    pg.image(this.eyes, 0, 0)
    pg.image(this.mouth, 0, 0)
    if (this.hair) pg.image(this.hair, 0, 0)
    if (this.extra) pg.image(this.extra, 0, 0)
    this.pg = pg
  }

  createOctoImage() {
    const img = this.p5.createImage(this.pg.width, this.pg.height)
    img.copy(
      this.pg,
      0,
      0,
      this.pg.width,
      this.pg.height,
      0,
      0,
      img.width,
      img.height
    )
    this.img = img
  }

  displayDialogue(pg) {
    this.p5.push()
    // translate(this.x * factor, this.y * factor);
    this.p5.translate(0, this.p5.height * 0.8)
    // flat black rect
    this.p5.fill(0)
    this.p5.rect(0, this.p5.height * 0.1, this.p5.width, this.p5.height * 0.1)
    // thumb
    this.p5.image(
      this.img,
      this.p5.width * -0.02,
      0,
      this.p5.width * 0.25,
      this.p5.width * 0.25
    )

    // bubble group
    this.p5.push()
    this.p5.translate(this.p5.width * 0.2, this.p5.height * -0.01)
    // bubble
    this.p5.fill(255)
    this.p5.noStroke()
    this.p5.rect(
      0,
      0,
      this.p5.width * 0.75,
      this.p5.height * 0.18,
      this.p5.width * 0.01
    )
    this.p5.beginShape()
    this.p5.vertex(-this.p5.width * 0.02, this.p5.height * 0.15)
    this.p5.vertex(0, this.p5.height * 0.12)
    this.p5.vertex(0, this.p5.height * 0.14)
    this.p5.endShape()
    // text
    this.p5.fill(0)
    this.p5.textSize(this.p5.height * 0.03)
    this.p5.textAlign(this.p5.LEFT)
    this.p5.text(
      this.dialogue[this.dialogueIndex],
      this.p5.width * 0.01,
      this.p5.height * 0.008,
      this.p5.width * 0.75,
      this.p5.height * 0.2
    )
    this.p5.pop()
    this.p5.pop()
  }

  display(pg) {
    pg.push()
    // if (this.hover === true) {
    //   pg.tint(200, 255, 100)
    // }
    pg.translate(this.x, this.y)
    pg.scale(this.direction, 1)
    pg.imageMode(this.p5.CENTER)
    pg.image(this.img, 0, 0)
    pg.pop()
  }
}
