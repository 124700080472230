export default class Bubble {
  constructor(p5) {
    this.p5 = p5;
    const x = this.p5.random(50, 70);
    const y = this.p5.random(50, 70);
    this.pos = p5.createVector(x, y);
    this.life = this.p5.random(1, 4); // second
  }

  update(dt) {
    this.pos.add(0, -dt * this.p5.random(1, 8));
    this.life -= dt;

    if (this.pos.y < 30 || this.life <= 0) {
      this.pos.x = this.p5.random(50, 70);
      this.pos.y = this.p5.random(50, 70);
      this.life = this.p5.random(1, 4);
    }
  }

  display(pg) {
    pg.push();
    pg.translate(Math.floor(this.pos.x) + 0.5, Math.floor(this.pos.y) + 0.5);
    const a = this.p5.map(this.life, 4, 0, 255, 0);
    pg.stroke(255, a);
    pg.point(0, 0);
    pg.pop();
  }
}
