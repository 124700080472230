/**
 * take octo attributes,
 * generate a dialogue array based on which eyes/mouth/extra it has.
 */

export default class Dialogue {
  constructor(tokenData) {
    this.createdAt = tokenData.createdAt
    this.name = tokenData.metadata.name
    this.owner = tokenData.owner
    this.rarity = tokenData.rarity
    this.attributes = tokenData.metadata.attributes

    this.basic = [
      //owner
      this.owner.name !== null
        ? `I am ${this.name}.\nDid my owner call me?\n${this.owner.name}?`
        : `I am ${this.name}.\nDoes my owner have a name?`,
      // //date
      // `I was created on ${new Date(this.createdAt).toLocaleDateString()}.`,
      // //rarity
      // this.rarity !== null
      //   ? `I am ${Math.floor(this.rarity * 100)}% rare, so they say.`
      //   : `I don't know my rarity. That's ok.`,
    ]

    this.special = []

    // extra
    switch (this.attributes[4].value) {
      case 1:
        // top octo
        this.special.push(`I think something's growing on my head.`)
      case 2:
        // top octo
        this.special.push(`Who's on my head?\nIs that...you...?`)
        break
      case 3:
        // glass helmet full
        this.special.push(`I don't want to take my helmet off yet.`)
        break
      case 4:
      case 5:
        // glass helmet half full
        this.special.push(`You gotta fill up my helmet.`)
        break
      default:
    }

    // eyes
    switch (this.attributes[1].value) {
      case 2:
        // crying
        this.special.push(`There's something in my eyes. I'm not crying.`)
        break
      case 7:
        // irritated
        this.special.push(`Why is everyone sleeping?`)
        break
      case 8:
      case 9:
        // focused
        this.special.push(`What..What happened?`)
        break
      case 13:
        // half closed
        this.special.push(`Something's fishy about this place...`)
        break
      case 14:
        // irritated
        this.special.push(`Who woke me up?`)
        break
      case 15:
      case 16:
        // indifferent
        this.special.push(`Are you my owner?`)
        break
      case 20:
      case 21:
      case 22:
        // eye patch
        this.special.push(`I'm the coolest Octo in this room! `)
        break
      case 23:
        // crying red
        this.special.push(`I had a scary dream. Or was it a dream?`)
        break
      case 24:
        // one eye
        this.special.push(
          `I can see better because my eye is big. Don't cheat on me!`
        )
        break
      default:
    }

    // mouth
    switch (this.attributes[2].value) {
      case 1:
      case 8:
        // pointy teeth
        this.special.push(`Time to go hunting!`)
        break
      case 6:
        // dissatisfied
        this.special.push(`I'm hungry. I don't want to talk.`)
        break
      case 9:
      case 13:
        // smile
        this.special.push(`GM! Thanks for waking me up.`)
        break
      case 15:
        // blood
        this.special.push(`This is ketchup... it's not what you think it is...`)
        break
      case 16:
      case 17:
        // beard
        this.special.push(`Should I keep my beard? Hmm...`)
        break
      case 18:
        // rainbow
        this.special.push(`I drank too much last night.`)
        break
      default:
    }
  }

  getDialogueBasic() {
    return this.basic
  }

  getDialogueSpecial() {
    // console.log(this.special);
    return this.special
  }
}
